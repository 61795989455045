import React, { useState } from 'react';
import {
    Button,
    Popover,
    Checkbox,
    List,
    ListItem,
    ListItemText,
    Box,
    styled
} from '@mui/material';

interface Props {
    options: { id: number, label: string }[],
    setSelectedColumns: (value: number[]) => void
}

const options = [
    { id: 1, label: 'Option 1' },
    { id: 2, label: 'Option 2' },
    { id: 3, label: 'Option 3' },
    { id: 4, label: 'Option 4' }
];

const FilterIcon = () => (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C0 1.55 0.45 2 1 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0H1C0.45 0 0 0.45 0 1ZM8 12H10C10.55 12 11 11.55 11 11C11 10.45 10.55 10 10 10H8C7.45 10 7 10.45 7 11C7 11.55 7.45 12 8 12ZM14 7H4C3.45 7 3 6.55 3 6C3 5.45 3.45 5 4 5H14C14.55 5 15 5.45 15 6C15 6.55 14.55 7 14 7Z" fill="#475569" />
    </svg>
)

function CustomPopover(props: Props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState<number[]>([]);

    const handleOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggleOption = (id: number) => {
        const updatedSelection = selectedOptions.includes(id)
            ? selectedOptions.filter((item: number) => item !== id)
            : [...selectedOptions, id];

        setSelectedOptions(updatedSelection);
        props.setSelectedColumns(updatedSelection);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'multi-select-popover' : undefined;

    const CheckIcon = () => (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="19" height="19" rx="5.5" stroke="#64748B" />
        </svg>
    )

    const CheckedIcon = () => (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="6" fill="#E89019" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0611 5.42238C15.5183 5.73229 15.6376 6.35411 15.3277 6.81124L10.2432 14.3112C10.0771 14.5562 9.8111 14.715 9.51671 14.745C9.22232 14.7749 8.92977 14.673 8.71777 14.4665L4.80234 10.6536C4.40667 10.2683 4.39827 9.6352 4.78358 9.23953C5.16888 8.84386 5.80199 8.83546 6.19766 9.22077L9.25771 12.2007L13.6723 5.68895C13.9822 5.23182 14.604 5.11247 15.0611 5.42238Z" fill="white" />
        </svg>
    )

    return (
        <div>
            <Button variant="outlined" onClick={handleOpen} startIcon={<FilterIcon />} style={{ color: "#475569", backgroundColor: "#FFFFFF", textTransform: "none", border: "1px solid #94A3B8", borderRadius: "8px" }}>
                Column Display
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    '& .MuiPopover-paper': {
                        borderRadius: "8px",
                        border: "1px solid #F1F5F9"
                    }
                }}
            >
                <List>
                    {props.options.map((option) => (
                        <ListItem key={option.id}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                                <CustomListItemText primary={option.label} />
                                <Checkbox
                                    data-test-id="checkIcon"
                                    icon={<CheckIcon />}
                                    checkedIcon={<CheckedIcon />}
                                    onChange={() => handleToggleOption(option.id)}
                                    color="primary"
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            borderRadius: '4px',
                                            borderColor: "#64748B"
                                        },
                                        '&.Mui-checked': {
                                            color: '#E89019',
                                        },
                                    }}
                                />
                            </Box>
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </div >
    );
}

const CustomListItemText = styled(ListItemText)({
    '& .MuiPopover-paper': {
        borderRadius: "8px"
    },
    '& .MuiTypography-root': {
        fontWeight: 400,
        fontSize: "16px",
        fontFamily: "DIN Round Pro",
        color: "#0F172A"
    },
    '& .MuiCheckbox-root': {

    }
})

export default CustomPopover;