import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    // Customizable Area Start
    navigation: string;
    id: string;
    // Customizable Area End
}

// Customizable Area Start
export interface Dropdown {
    label: string;
    value: string;
}
// Customizable Area End

interface S {
    // Customizable Area Start
    tasks: {task: string, date: string, progress: number}[],
    tabValue: number,
    hiddenColumn: string[],
    openCreateClientModal: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: number;
    // Customizable Area End
}

export default class TeamPerformanceController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            tasks: [
                { task: 'Lorem Ipsum Dolor', date: '02/08/2021', progress: 75 },
                { task: 'Lorem Ipsum Dolor', date: '12/06/2020', progress: 25 },
                { task: 'Lorem Ipsum Dolor', date: '12/06/2020', progress: 51 }
            ],
            tabValue: 0,
            hiddenColumn: [],
            openCreateClientModal: false
            // Customizable Area End
        };

        // Customizable Area Start
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    setHiddenColumns = (options: number[]) => {
    }
    // Customizable Area End
}
