import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  collapsed: boolean,
  selectedIcon: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LeftMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
    ];

    this.state = {
      collapsed: false,
      selectedIcon: ""
    }


    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const currentURL = new URL(document.URL);
    const currentPathname = currentURL.pathname;
    if (currentPathname) {
      this.setState({
        selectedIcon: currentPathname.split("/").join("").toLowerCase()
      })
    }
  }

  handleMenuChange = (path: string) => {
    this.setState({ selectedIcon: path })
    this.handleNavigation(path)
  }

  handleNavigation = (path: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleTwoCondition = (condition: boolean, truePart: string, falsePart: string) => {
    return condition ? truePart : falsePart
  }

  handleGridSize = () => this.state.collapsed ? 1 : 2;

  // Customizable Area End
}
