import { Button, ButtonProps, styled } from "@mui/material";
import React, { FC } from "react";

interface CustomButtonProps extends ButtonProps {
    title: string;
    bgColor?: string;
    fontColor?: string;
}

const StyledButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    fontFamily: "DIN Round Pro",
    fontSize: "16px",
    fontWeight: 700,
    padding: "8px 11px",
    boxShadow: "none",
}));

export const CustomButton: FC<CustomButtonProps> = ({ 
    title, 
    bgColor = "#FF9E1B", 
    fontColor = "#FFFFFF", 
    ...props 
}) => (
    <StyledButton
        variant="contained"
        style={{ backgroundColor: bgColor, color: fontColor }}
        {...props}
    >
        {title}
    </StyledButton>
);
