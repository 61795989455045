// Customizable Area Start
import React from 'react';
import { Box, Divider, Grid, Pagination, styled, Tab, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tabs, Typography } from "@mui/material";
import TeamPerformanceController, {
  configJSON,
  Props,
} from "./TeamPerformanceController.web";
import { searchIcon } from './assets';
import CustomPopover from './components/CustomPopover';
import LeftMenuWeb from '../../../../packages/blocks/landingpage/src/LeftMenu.web';

// Customizable Area End

export default class TeamPerformance extends TeamPerformanceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderTableData = () => {
    return (
      <Table stickyHeader >
        <TableHead style={{ backgroundColor: "#FFFFF", }}>
          <TableRow>
            {
              configJSON.tableTitles.map((title: { id: number, sorting: boolean, label: string }) => {
                return (
                  <TableCellstyle style={webStyles.tableText}>
                    <TableSortLabel hideSortIcon={!title.sorting} active={title.sorting} IconComponent={this.SortIcon}>{title.label}</TableSortLabel>
                  </TableCellstyle>
                )
              })
            }
            <TableCellstyle style={webStyles.tableText}></TableCellstyle>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.tasks && this.state.tasks.map((task: any, indexx: any) => (
            <TableRow key={indexx} >
              <TableCell style={webStyles.tableText}>Esther Howard</TableCell >
              <TableCell style={webStyles.tableText}>2024</TableCell>
              <TableCell style={webStyles.tableText}>$15544</TableCell>
              <TableCell style={webStyles.tableText}>$7000</TableCell>
              <TableCell style={webStyles.tableText}>$5544</TableCell>
              <TableCell style={webStyles.tableText}>$5000</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }

  SortIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.70273 15.1202L11.2927 17.7102C11.6827 18.1002 12.3127 18.1002 12.7027 17.7102L15.2927 15.1202C15.9227 14.4902 15.4727 13.4102 14.5827 13.4102H9.40273C8.51273 13.4102 8.07273 14.4902 8.70273 15.1202Z" fill="#0F172A" />
      <path d="M8.70273 8.88277L11.2927 6.29277C11.6827 5.90277 12.3127 5.90277 12.7027 6.29277L15.2927 8.88277C15.9227 9.51277 15.4727 10.5928 14.5827 10.5928H9.40273C8.51273 10.5928 8.07273 9.51277 8.70273 8.88277Z" fill="#0F172A" />
    </svg>
  )

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Grid container>
          <LeftMenuWeb navigation={this.props.navigation} id={''} />
          <Grid item lg={10} md={10} sm={10} >
            <Box style={webStyles.container}>
              <Box >
                <Box
                  sx={{
                    ...webStyles.mainBox,
                    alignItems: { xs: 'start', sm: 'start', },
                    flexDirection: { xs: 'column', sm: 'column', md: "row" }
                  }}
                >
                  <Typography style={webStyles.heading}>Team Performance</Typography>
                </Box>
                <Box>
                  <Box style={webStyles.tableBox}>
                    <Box
                      sx={{
                        ...webStyles.tableTop,
                        display: "flex",
                        alignItems: { xs: "start", sm: "start", md: "center" },
                        flexDirection: { xs: "column-reverse", sm: "column-reverse", md: "row" },
                        gap: "12px"
                      }}
                    >
                      <Box style={webStyles.searchFilterIcon}>
                        <img style={{ cursor: "pointer" }} src={searchIcon} width="24px" />
                        <CustomPopover data-test-id="customPopover" options={configJSON.tableTitles} setSelectedColumns={this.setHiddenColumns} />
                      </Box>
                    </Box>
                    <Divider />
                    {this.renderTableData()}
                  </Box>

                </Box>
              </Box>
              <Box style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                alignItems: 'end'
              }}>
                <Box style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                  <CustomPagination count={5} />
                </Box>
                <Box style={{ marginRight: "22px", display: 'flex', justifyContent: 'flex-end' }}>
                  <Typography style={webStyles.recordsText}>1 to 10 of <span style={{ fontWeight: 700 }}>12 records</span></Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CustomPagination = styled(Pagination)({
  "& .MuiPagination-ul": {
    justifyContent: "center",
  },
  "& .MuiPaginationItem-root": {
    marginTop: "45px",
    "&.Mui-selected:hover": {
      backgroundColor: "transparent"
    },
    "&.Mui-selected": {
      color: "#E89019",
      backgroundColor: "transparent"
    }
  }
});


const TableCellstyle = styled(TableCell)({
  backgroundColor: '#F1F5F9',
  border: '4px solid white',
})

const CustomTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#E89019',
  },
});

const CustomTab = styled(Tab)({
  textTransform: "none",
  fontWeight: 700,
  fontSize: "16px",
  fontFamily: "DIN Round Pro",
  '&.Mui-selected': {
    color: '#E89019'
  },
});

const webStyles = {
  container: {
    backgroundColor: "#F8FAFC",
    height: "100%"
  },
  mainBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: "24px 0px",
    border: "1px solid #E2E8F0",
    backgroundColor: "#FFFFFF"
  },
  heading: {
    fontFamily: "DIN Round Pro",
    fontSize: "24px",
    fontWeight: 700,
    color: "#0F172A",
    marginLeft: "32px"
  },
  importLink: {
    color: "#64748B",
    fontFamily: "DIN Round Pro",
    fontSize: "16px",
    marginLeft: "2px",
    textDecoration: "underline"
  },
  rightBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: "48px",
    gap: "8px"
  },
  tableBox: {
    backgroundColor: '#fff',
    marginLeft: '24px',
    marginTop: '24px',
    borderRadius: '12px',
    padding: "14px"
  },
  tableTop: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    padding: "0px 50px 20px 0px",
    flexDirection: "row"
  },
  searchFilterIcon: {
    display: "flex",
    gap: "13px",
  },
  tableText: {
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "DIN Round Pro",
    color: "#0F172A"
  },
  progressText: {
    fontSize: "12px",
    fontWeight: 700,
    color: "#0F172A",
    fontFamily: "DIN Round Pro",
  },
  recordsText: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#475569",
    fontFamily: "DIN Round Pro"
  }
}
// Customizable Area End
