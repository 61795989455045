// Customizable Area Start
import React from 'react';
import { Box, Divider, Grid, IconButton, LinearProgress, Pagination, styled, Tab, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tabs, Typography } from "@mui/material";
import ClientsPageController, {
  configJSON,
  Props,
} from "./ClientsPageController.web";
import { DownloadIcon, SearchIcon } from "./assets";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomForm from "./CustomForm.web";
import { CustomButton } from './components/CustomButton';
import CustomPopover from './components/CustomPopover';
import LeftMenuWeb from '../../../../packages/blocks/landingpage/src/LeftMenu.web';

// Customizable Area End

export default class ClientsPage extends ClientsPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderTableData = () => {
    return (
      <Table stickyHeader >
        <TableHead style={{ backgroundColor: "#FFFFF", }}>
          <TableRow>
            {
              configJSON.tableTitles.map((title: { id: number, sorting: boolean, label: string }) => {
                return (
                  <TableCellstyle style={webStyles.tableText}>
                    <TableSortLabel hideSortIcon={!title.sorting} active={title.sorting} IconComponent={this.SortIcon}>{title.label}</TableSortLabel>
                  </TableCellstyle>
                )
              })
            }
            <TableCellstyle style={webStyles.tableText}></TableCellstyle>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.tasks && this.state.tasks.map((task: any, indexx: any) => (
            <TableRow key={indexx} >
              <TableCell style={webStyles.tableText}>Esther Howard</TableCell >
              <TableCell style={webStyles.tableText}>120003</TableCell>
              <TableCell style={webStyles.tableText}>tanya.hill@example.com</TableCell>
              <TableCell style={webStyles.tableText}>Ralph Edwards</TableCell>
              <TableCell style={webStyles.tableText}>449003</TableCell>
              <TableCell style={webStyles.tableText}>(229) 555-0109</TableCell>
              <TableCell style={webStyles.tableText}>3605 Parker Rd.</TableCell>
              <TableCell style={webStyles.tableText}>600000</TableCell>
              <TableCell style={webStyles.tableText}>700000</TableCell>
              <TableCell style={webStyles.tableText}>{task.date}</TableCell>
              <TableCell>
                <Box style={{ ...webStyles.tableText, display: "flex", alignItems: "center", gap: "10px" }}>
                  <LinearProgress
                    variant="determinate"
                    value={task.progress}
                    sx={{
                      width: '100px',
                      backgroundColor: '#e0e0e0',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#32D39A',
                      },
                    }}
                  />
                  <Typography variant="caption" style={webStyles.progressText}>{task.progress}%</Typography>
                </Box>
              </TableCell>
              <TableCell>
                <IconButton>
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }

  SortIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.70273 15.1202L11.2927 17.7102C11.6827 18.1002 12.3127 18.1002 12.7027 17.7102L15.2927 15.1202C15.9227 14.4902 15.4727 13.4102 14.5827 13.4102H9.40273C8.51273 13.4102 8.07273 14.4902 8.70273 15.1202Z" fill="#0F172A" />
      <path d="M8.70273 8.88277L11.2927 6.29277C11.6827 5.90277 12.3127 5.90277 12.7027 6.29277L15.2927 8.88277C15.9227 9.51277 15.4727 10.5928 14.5827 10.5928H9.40273C8.51273 10.5928 8.07273 9.51277 8.70273 8.88277Z" fill="#0F172A" />
    </svg>
  )

  clientsIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.8943 10.9413C15.0359 11.7163 15.8359 12.7663 15.8359 14.1663V16.6663H19.1693V14.1663C19.1693 12.3497 16.1943 11.2747 13.8943 10.9413ZM12.5026 9.99967C14.3443 9.99967 15.8359 8.50801 15.8359 6.66634C15.8359 4.82467 14.3443 3.33301 12.5026 3.33301C12.1109 3.33301 11.7443 3.41634 11.3943 3.53301C12.0859 4.39134 12.5026 5.48301 12.5026 6.66634C12.5026 7.84967 12.0859 8.94134 11.3943 9.79967C11.7443 9.91634 12.1109 9.99967 12.5026 9.99967ZM7.5026 9.99967C9.34427 9.99967 10.8359 8.50801 10.8359 6.66634C10.8359 4.82467 9.34427 3.33301 7.5026 3.33301C5.66094 3.33301 4.16927 4.82467 4.16927 6.66634C4.16927 8.50801 5.66094 9.99967 7.5026 9.99967ZM7.5026 4.99967C8.41927 4.99967 9.16927 5.74967 9.16927 6.66634C9.16927 7.58301 8.41927 8.33301 7.5026 8.33301C6.58594 8.33301 5.83594 7.58301 5.83594 6.66634C5.83594 5.74967 6.58594 4.99967 7.5026 4.99967ZM7.5026 10.833C5.2776 10.833 0.835938 11.9497 0.835938 14.1663V16.6663H14.1693V14.1663C14.1693 11.9497 9.7276 10.833 7.5026 10.833ZM12.5026 14.9997H2.5026V14.1747C2.66927 13.5747 5.2526 12.4997 7.5026 12.4997C9.7526 12.4997 12.3359 13.5747 12.5026 14.1663V14.9997Z"
        fill={this.state.tabValue === 0 ? "#E89019" : "#64748B"} />
    </svg>
  )

  greenFieldClientIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.9974 1.66699C5.3974 1.66699 1.66406 5.40033 1.66406 10.0003C1.66406 14.6003 5.3974 18.3337 9.9974 18.3337C14.5974 18.3337 18.3307 14.6003 18.3307 10.0003C18.3307 5.40033 14.5974 1.66699 9.9974 1.66699ZM9.9974 16.667C6.3224 16.667 3.33073 13.6753 3.33073 10.0003C3.33073 6.32533 6.3224 3.33366 9.9974 3.33366C13.6724 3.33366 16.6641 6.32533 16.6641 10.0003C16.6641 13.6753 13.6724 16.667 9.9974 16.667ZM8.33073 11.8087L13.2307 6.90866C13.5557 6.58366 14.0891 6.58366 14.4141 6.90866C14.7391 7.23366 14.7391 7.75866 14.4141 8.08366L8.9224 13.5753C8.5974 13.9003 8.0724 13.9003 7.7474 13.5753L5.58906 11.417C5.26406 11.092 5.26406 10.567 5.58906 10.242C5.74476 10.0859 5.95613 9.99825 6.17656 9.99825C6.39699 9.99825 6.60837 10.0859 6.76406 10.242L8.33073 11.8087Z"
        fill={this.state.tabValue === 1 ? "#E89019" : "#64748B"} />
    </svg>
  )
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Grid container>
          <LeftMenuWeb navigation={this.props.navigation} id={''} />
          <Grid item lg={10} md={10} sm={10}>
            <Box style={webStyles.container}>
              <Box >
                <Box
                  sx={{
                    ...webStyles.mainBox,
                    alignItems: { xs: 'start', sm: 'start', },
                    flexDirection: { xs: 'column', sm: 'column', md: "row" }
                  }}
                >
                  <Typography style={webStyles.heading}>Clients</Typography>
                  <Box style={webStyles.rightBox}>
                    <Box display={"flex"} sx={{ marginLeft: { xs: "30px", sm: "30px" } }}>
                      <img src={DownloadIcon} />
                      <Typography style={webStyles.importLink}>
                        Import lead
                      </Typography>
                    </Box>
                    <CustomButton data-test-id="CustomButton" title="View Edit Request" />
                    <CustomButton title="Create New Client" onClick={this.handleCreateClient} data-test-id="createClient" />
                  </Box>
                </Box>
                <Box>
                  <Box style={webStyles.tableBox}>
                    <Box
                      sx={{
                        ...webStyles.tableTop,
                        display: "flex",
                        alignItems: { xs: "start", sm: "start", md: "center" },
                        flexDirection: { xs: "column-reverse", sm: "column-reverse", md: "row" },
                        gap: "12px"
                      }}
                    >
                      <Box>
                        <CustomTabs value={this.state.tabValue} onChange={this.handleTabChange}>
                          <CustomTab icon={this.clientsIcon()} iconPosition="start" label="Clients" />
                          <CustomTab icon={this.greenFieldClientIcon()} iconPosition="start" label="Green field clients" />
                        </CustomTabs>
                      </Box>
                      <Box style={webStyles.searchFilterIcon}>
                        <img src={SearchIcon} width="24px" />
                        <CustomPopover data-test-id="CustomPopover" options={configJSON.tableTitles} setSelectedColumns={this.setHiddenColumns} />
                      </Box>
                    </Box>
                    <Divider />
                    {this.renderTableData()}
                  </Box>

                </Box>
              </Box>
              <Box style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                alignItems: 'end'
              }}>
                <Box style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                  <CustomPagination count={5} />
                </Box>
                <Box style={{ marginRight: "22px", display: 'flex', justifyContent: 'flex-end' }}>
                  <Typography style={webStyles.recordsText}>1 to 10 of <span style={{ fontWeight: 700 }}>12 records</span></Typography>
                </Box>
              </Box>
              {this.state.openCreateClientModal && <CustomForm data-test-id="customForm" navigation={""} id={""} onClose={() => this.handleCloseModal()} />}
            </Box>
          </Grid>
        </Grid>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CustomPagination = styled(Pagination)({
  "& .MuiPagination-ul": {
    justifyContent: "center",
  },
  "& .MuiPaginationItem-root": {
    marginTop: "45px",
    "&.Mui-selected:hover": {
      backgroundColor: "transparent"
    },
    "&.Mui-selected": {
      color: "#E89019",
      backgroundColor: "transparent"
    }
  }
});


const TableCellstyle = styled(TableCell)({
  backgroundColor: '#F1F5F9',
  border: '4px solid white',
})

const CustomTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#E89019',
  },
});

const CustomTab = styled(Tab)({
  textTransform: "none",
  fontWeight: 700,
  fontSize: "16px",
  fontFamily: "DIN Round Pro",
  '&.Mui-selected': {
    color: '#E89019'
  },
});

const webStyles = {
  container: {
    backgroundColor: "#F8FAFC",
    paddingBottom: "200px",
    height: "100%"
  },
  mainBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: "24px 0px",
    border: "1px solid #E2E8F0",
    backgroundColor: "#FFFFFF"
  },
  heading: {
    fontFamily: "DIN Round Pro",
    fontSize: "24px",
    fontWeight: 700,
    color: "#0F172A",
    marginLeft: "32px"
  },
  importLink: {
    color: "#64748B",
    fontFamily: "DIN Round Pro",
    fontSize: "16px",
    marginLeft: "2px",
    textDecoration: "underline"
  },
  rightBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: "48px",
    gap: "8px"
  },
  tableBox: {
    backgroundColor: '#fff',
    marginLeft: '24px',
    marginTop: '24px',
    borderRadius: '12px',
    padding: "14px"
  },
  tableTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: "0px 50px 20px 0px",
    flexDirection: "row"
  },
  searchFilterIcon: {
    display: "flex",
    gap: "13px",
  },
  tableText: {
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "DIN Round Pro",
    color: "#0F172A"
  },
  progressText: {
    fontSize: "12px",
    fontWeight: 700,
    color: "#0F172A",
    fontFamily: "DIN Round Pro",
  },
  recordsText: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#475569",
    fontFamily: "DIN Round Pro"
  }
}
// Customizable Area End
